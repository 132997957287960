import ProgressBar from './progress-bar.js';

class InventoryProgressBar extends ProgressBar {
  constructor() {
    super();
    const orders = this.dataset.order;
    const available = this.dataset.available;
    this.init(orders, available);
  }

  init(orders, available) {
    const min = Number(this.dataset.feAmount);
    const threshold = Number(this.dataset.threshold);
    if (threshold > orders) {
      if (orders > 0) {
        this.classList.add('notify', 'low_stock');
        this.classList.remove('instock', 'pre_order', 'outstock');
      } else {
        if (available == false || available == 'false') {
          this.classList.add('notify', 'outstock');
          this.classList.remove('instock', 'pre_order', 'low_stock');
        } else {
          this.classList.remove('notify', 'instock', 'low_stock', 'outstock');
          this.classList.add('pre_order');
        }
      }
    } else {
      this.classList.remove('notify', 'pre_order', 'low_stock', 'outstock');
      this.classList.add('instock');
    }
    if (!min) return;
    if (orders === undefined) return;
    const order = Number(orders);
    if (order === undefined) return;
    if ((order / min) * 100 > 100) {
      this.setProgressBar(100);
    } else {
      this.setProgressBar((order / min) * 100);
    }
    this.setProgressBarTitle(order, threshold, available);
  }

  setProgressBarTitle(order, threshold, available) {
    const lowStock = this.dataset.lowStock;
    const soldOut = this.dataset.soldOut;
    const preOrder = this.dataset.preOrder;
    const availableMessage = this.dataset.availableMessage;
    const title = this.querySelector('.progress-bar-message .message');
    if (!title) return;
    if (order > 0) {
      if (threshold > order) {
        title.innerHTML = `${lowStock.replace('{{ count }}', order)}`;
      } else {
        title.innerHTML = availableMessage;
      }
    } else {
      if (available == 'true' || available == true) {
        title.innerHTML = preOrder;
      } else {
        title.innerHTML = soldOut;
      }
    }
  }
}

export default InventoryProgressBar;
